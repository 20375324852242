/** @format */

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  BinCharterIcon,
  HomeIcon,
  InvoiceOutlineIcon,
  Program_manage,
  ResourcesIcon,
  ServiceIcon,
  SettingIcon,
  TeamOutlineIcon,
  UniversalIcon,
  UserAccessIcon,
} from '../components/icon/Icons';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import PartnerManagement from '../mcc/Program-Dashboard/pages/PartnerManagement';
import BinCharter from '../pages/BinCharter';
import ClientDashBoard from '../pages/ClientDashBoard';
import FinanceCenter from '../pages/FinanceCenter';
import ManageTeams from '../pages/ManageTeams';
import MoreClientOptions from '../pages/MoreClientOptions';
import ProgramManagement from '../pages/ProgramManagement';
import Resources from '../pages/Resources';
import RolesAndAccess from '../pages/RolesAndAccess';
import ServicesSetup from '../pages/ServicesSetup';
import UniversalStandards from '../pages/UniversalStandards';
import { DASHBOARD_ROUTES, ROUTES, SUB_ROUTES } from '../routes';
import { BinCharterProvider } from '../providers/BinCharterProvider';
import Invoice from '../pages/FinanceCenter/Invoice/Invoice';
import IncomeExpense from '../pages/FinanceCenter/IncomeExpense/IncomeExpense';
import ProfitLoss from '../pages/FinanceCenter/ProfitLoss/ProfitLoss';
import { ProfitLossProvider } from '../providers/ProfitLossProvider';

type Props = {};

const NAV_OPTIONS: SidebarListItemType[] = [
  {
    id: ROUTES.HOME,
    icon: <HomeIcon />,
    label: 'Home',
    component: <ClientDashBoard />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.PROGRAM_MANAGE,
    icon: <Program_manage />,
    label: 'Program Management',
    component: <ProgramManagement />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.PARTNER_MANAGE,
    icon: <Program_manage />,
    label: 'Partner management',
    component: <PartnerManagement />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.SERVICE_SETUP,
    icon: <ServiceIcon />,
    label: 'Service Setup',
    component: <ServicesSetup />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.RESOURCES,
    icon: <ResourcesIcon />,
    label: 'Resources',
    component: <Resources />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.MANAGE_TEAMS,
    icon: <TeamOutlineIcon />,
    label: 'Manage Teams',
    component: <ManageTeams />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.ROLES_AND_ACCESS,
    icon: <UserAccessIcon />,
    label: 'Roles & Access',
    component: <RolesAndAccess />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.UNI_STANDARDS,
    icon: <UniversalIcon />,
    label: 'Institution Standards',
    component: <UniversalStandards />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.BIN_CHARTER,
    icon: <BinCharterIcon />,
    label: 'Bin Charter',
    component: (
      <BinCharterProvider>
        <BinCharter />
      </BinCharterProvider>
    ),
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.FINANCE_CENTER,
    icon: <InvoiceOutlineIcon />,
    label: 'Finance Center',
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
    children: [
      {
        id: SUB_ROUTES.INVOICE,
        label: 'Invoice',
        component: <Invoice />,
      },
      // {
      //   id: SUB_ROUTES.INCOME_EXPENSE,
      //   label: 'Income & Expense',
      //   component: <IncomeExpense />,
      // },
      {
        id: SUB_ROUTES.PROFIT_LOSS,
        label: 'Profit & Loss',
        component: (
          <ProfitLossProvider>
            <ProfitLoss />
          </ProfitLossProvider>
        ),
      },
    ],
  },
  {
    id: ROUTES.CLIENT_MORE_OPTIONS,
    icon: <SettingIcon />,
    label: 'More',
    component: <MoreClientOptions />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
];
export const SELECTED_VIEW = 'view';
const ClientDashboardContainer = (props: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType>(
    NAV_OPTIONS[0]
  );

  useEffect(() => {
    setSearchParams(createSearchParams({ [SELECTED_VIEW]: selectedItem.id }));
  }, []);

  const handleClick = (item: SidebarListItemType) => {
    if (item.id === '') {
      navigate(DASHBOARD_ROUTES.CLIENT_DASHBOARD);
    } else if (item.isClientRoutes) {
      const existingId = localStorage.getItem('clientId');
      if (existingId) {
        setSelectedItem(item);
        setSearchParams(createSearchParams({ [SELECTED_VIEW]: item.id }));
      } else {
        navigate(ROUTES.LOGIN);
      }

      // navigate(ROUTES.RESOURCES);
    } else {
      navigate(item.id);
    }
  };

  return (
    <Box>
      <SidebarLayout
        sidebarListItems={NAV_OPTIONS}
        onItemClick={handleClick}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default ClientDashboardContainer;
