import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig, oidcProdConfig } from '../oidc';
import { isCimsProd } from '../constants/url';

const config = isCimsProd ? oidcProdConfig : oidcConfig;

function OidcProvider({ children }: React.PropsWithChildren) {
  return (
    <AuthProvider
      {...config}
      onSigninCallback={(userData) => {
        // window.history.replaceState(
        //   {},
        //   document.title,
        //   window.location.pathname
        // );
        console.log('****OIDC User', userData);
        localStorage.setItem('token', `${userData?.access_token}`);
      }}
    >
      {children}
    </AuthProvider>
  );
}

export default OidcProvider;
