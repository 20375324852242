import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '..';

let localAuth: any = localStorage.getItem('isAuth');

export interface IProps {
  isAuth: boolean;
}
const initialState: IProps = {
  isAuth: JSON.parse(localAuth) ? JSON.parse(localAuth) : false,
};

const authSlice = createSlice({
  name: 'cimsAuth',
  initialState,
  reducers: {
    setIsAuth: (state: IProps, action) => {
      const { payload } = action;
      state.isAuth = payload;
      localStorage.setItem('isAuth', JSON.stringify(payload));
    },
  },
});

export const { setIsAuth } = authSlice.actions;
export const getCIMS_Credentials = (state: AppState) => state.cimsAuth;
export default authSlice;
