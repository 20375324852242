/** @format */

import axios from 'axios';
import { isCimsProd, isCimsUAT, isDev } from '../constants/url';

const API_GATEWAY = 'https://prod-api.vegapay.tech';
const UAT_GATEWAY =
  'https://vb814turbc.execute-api.ap-south-1.amazonaws.com/UAT';
const PROD_GATEWAY =
  'https://vct7163r4d.execute-api.ap-south-1.amazonaws.com/prod';
const ALPHA_API_GATEWAY = 'https://alpha-api.vegapay.tech';
const env = {
  alpha_gateway: '/alpha',
  prod_gateway: '',
  sandbox_gateway: '/sandbox',
};
export const API_BASE_URL = `${
  isDev
    ? ALPHA_API_GATEWAY
    : isCimsProd
    ? PROD_GATEWAY
    : isCimsUAT
    ? UAT_GATEWAY
    : API_GATEWAY
}`;

export const configureAxiosDefaults = () => {
  const currentHost = window.location.hostname;
  const BASE_URL = API_BASE_URL;
  console.log('BASE URL', BASE_URL);
  axios.defaults.baseURL = BASE_URL;
};

//request interceptor
axios.interceptors.request.use(
  (config: any) => {
    // config.headers['Authorization'] = `${sessionStorage.getItem('token')}`;
    config.headers['Authorization'] = `${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//response interceptor
// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.status == 401) {
//       Auth.currentSession();
//     }
//     return Promise.reject(error);
//   }
// );
